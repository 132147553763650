import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  matchFactory,
  matchForPlayer,
  currentMatch,
  createMatch,
  addPlayer,
  matchStatus,
  getPrice,
  setInfoForPlayer,
  postScoreForPlayer,
  connectWallet,
  getCurrentWalletConnected,
  startMatch,
} from "./util/interact.js";
import './App.css';

import mylogo from "./gamblinks.svg";

import { withStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from '@mui/material/Button';

const Gambler = () => {
  //Match State enum
  const CREATED = 0;
  const STARTED = 1;
  const SCORES_POSTED = 2;
  const PAYOUT = 3;
  const ENDED = 4;
  //Match Type enum
  const FRONT = 0;
  const BACK = 1;
  const TOTAL = 2;

  //state variables
  const [progress, setProgress] = useState(false);
  const [activeComponent, setActiveComponent] = useState("");
  const [playerList, setPlayerList] = useState([]);
  const [matchList, setMatchList] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [currentIntPrice, setCurrentIntPrice] = useState(0);

  const walletAddress = useRef("");
  const transactionHash = useRef("");
  const playerName = useRef("");
  const matchName = useRef("");
  const coordinatorName = useRef("");
  const currentMatchAddress = useRef("");
  const isCoordinator = useRef(false);
  const matchState = useRef(0);
  const scorePosted = useRef(false);

  const CustomTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'rgb(255, 255, 255, 0.55)',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'rgb(211, 239, 189)',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgb(255, 255, 255, 0.55)',
          color: 'rgb(255, 255, 255, 0.55)',
        },
        '&:hover fieldset': {
          borderColor: 'rgb(255, 255, 255, 0.55)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgb(211, 239, 189)',
        },
      },
      '& .MuiInputLabel-root': {
        color: 'rgb(255, 255, 255, 0.75)',
      },
      '& .MuiInputBase-input': {
        color: 'rgb(255, 255, 255, 0.75)',
        fontSize: '16pt',
      },
      '& .MuiFormHelperText-root': {
        color: 'rgb(255, 255, 255, 0.35)',
      },
    },
  })(TextField);

  //called only once
  useEffect(() => {
    addSmartContractListener();
    
    setProgress(false);

    async function fetchWallet() {
      
      const {address, status} = await getCurrentWalletConnected();
      walletAddress.current = address;
      
      if(address) {
        if(currentMatchAddress.current)
        {
          if(matchState.current == CREATED) {
            setActiveComponent("MATCH_SUMMARY");
          }
          if(matchState.current == STARTED) {
            setActiveComponent("MATCH_SCORING");
          }
          if(matchState.current == ENDED) {
            setActiveComponent("MATCH_RESULTS");
          }
        } else {
          //get matches for player address
          onRefreshMatchList();
    
          setActiveComponent("START_PAGE");
        }
      } else {
        setActiveComponent("CONNECT_WALLET");
      }
      
    }
    fetchWallet();

    addWalletListener(); 
  }, []);

  const price = async () => {
    const raw = await getPrice();
    const current_price = Number((raw / 10**18).toFixed(2));
    setCurrentPrice(current_price);
    setCurrentIntPrice(raw);
  }

  function addSmartContractListener() {

    matchFactory.events.OnMatchCreated({
      fromBlock:'latest'}, (error, data) => {
      
      console.log("EVENT LISTENER");
      console.log("DATA: %s",data.transactionHash);
      console.log("LOCAL: %s", transactionHash.current);

      if(data.transactionHash == transactionHash.current) {
        console.log("NEW MATCH CREATED");
        
        matchState.current = CREATED;

        currentMatchAddress.current = data.returnValues["match_address"];
        matchName.current = data.returnValues["match_name"];

        async function status() {
          const {0:match_name, 1:status, 2:player_list} = await matchStatus(currentMatchAddress.current);
          matchState.current = status;
          setPlayerList(player_list);
          
          // TEST FOR COORDINATOR STATUS
          for(let i=0; i < player_list.length; i++) {
            if(player_list[i].addr.toLowerCase() === walletAddress.current.toLowerCase()) {
              isCoordinator.current = player_list[i].isCoordinator;
            }
          }

          setProgress(false);

          setActiveComponent("MATCH_SUMMARY");
        }
        status();

        addMatchListeners();
      }
    });
  }

  function addMatchListeners() {
    console.log("ADD THE LISTENERS HERE");

    currentMatch(currentMatchAddress.current).events.OnPlayerAdded({
      fromBlock:'latest'}, (error, data) => {
      console.log("OnPlayerAdded");
      async function status() {
        const {0:match_name, 1:status, 2:player_list} = await matchStatus(currentMatchAddress.current);
        matchState.current = status;
        setPlayerList(player_list);

        setProgress(false);
        setActiveComponent("MATCH_SUMMARY");

        console.log("OnPlayerAdded -- status");
      }
      status();

    });

    currentMatch(currentMatchAddress.current).events.OnPlayerInfoUpdated({
      fromBlock:'latest'}, (error, data) => {
        console.log("OnPlayerInfoUpdated");
        console.log("ok. player is updated");
        console.log(matchState.current);
        console.log(progress);

        matchState.current = data.returnValues.state;
        setPlayerList(data.returnValues.players);

        if(data.returnValues.player_Address.toLowerCase() === walletAddress.current.toLowerCase()) {
          setProgress(false);
          setActiveComponent("MATCH_SUMMARY");
        }
    });

    currentMatch(currentMatchAddress.current).events.OnMatchStarted({
      fromBlock:'latest'}, (error, data) => {
        console.log("OnMatchStarted");

        async function status() {
          const {0:match_name, 1:status, 2:player_list} = await matchStatus(currentMatchAddress.current);
          matchState.current = status;
          setPlayerList(player_list);

          setProgress(false);

          setActiveComponent("MATCH_SCORING");
          console.log("OnMatchStarted -- status");
        }
        status();

        console.log("Match Started");
    });

    currentMatch(currentMatchAddress.current).events.OnScorePosted({
      fromBlock:'latest'}, (error, data) => {
        console.log("OnScorePosted");
        const state = data.returnValues.state;
        const player = data.returnValues.player;
        const player_list = data.returnValues.players;
        if(player.addr.toLowerCase() === walletAddress.current.toLocaleLowerCase()){
          scorePosted.current = true;
          setProgress(false);
          setActiveComponent("MATCH_SCORING");
        }

        onRefreshData();
    });

    currentMatch(currentMatchAddress.current).events.OnMatchEnded({
      fromBlock:'latest'}, (error, data) => {
        const name = data.returnValues.match_name;
        matchState.current = data.returnValues.state;
        setPlayerList(data.returnValues.players);
        setProgress(false);

        setActiveComponent("MATCH_RESULTS");
    });
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          walletAddress.current = accounts[0];
          setActiveComponent("START_PAGE");
        } else {
          walletAddress.current = "";
          setActiveComponent("CONNECT_WALLET");
        }
      });
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    walletAddress.current = walletResponse.address;
  };

  const onCreateMatch = async (cName, mName) => {
    coordinatorName.current = cName;
    matchName.current = mName;
    const txHash = await createMatch(walletAddress.current, cName, mName);

    transactionHash.current = txHash;
    console.log("TXHASH: %s", txHash);

    setProgress(true);

    return txHash;
  };

  const onStartMatch = async () => {
    const txHash = await startMatch(walletAddress.current, currentMatchAddress.current);

    setProgress(true);

    return txHash;
  };

  const onMatchDelete = async () => {
    console.log("Delete this match pronto");
  };

  const onAddPlayerAddress = async (pName, pAddress) => {
  
    const txAdd = await addPlayer(walletAddress.current, currentMatchAddress.current, pName, pAddress);

    setProgress(true);
  };

  function onPlayerInfoTap() {

    if(currentPrice == 0) {
      price();
    }
    
    setActiveComponent("EDIT_PLAYER");
  }

  function onRefreshData() {
    async function status() {
      const {0:match_name, 1:status, 2:player_list} = await matchStatus(currentMatchAddress.current);
      matchState.current = status;
      setPlayerList(player_list);
    }
    status();
  }

  function onMatchListTap(name, address) {
    currentMatchAddress.current = address;
    matchName.current = name;
    async function status() {
      const {0:match_name, 1:status, 2:player_list} = await matchStatus(currentMatchAddress.current);
      matchState.current = status;
      setPlayerList(player_list);
      // TEST FOR COORDINATOR STATUS
      for(let i=0; i < player_list.length; i++) {
        if(player_list[i].addr.toLowerCase() === walletAddress.current.toLowerCase()) {
          isCoordinator.current = player_list[i].isCoordinator;
        }
      }
      addMatchListeners();
      
      switch(parseInt(matchState.current)) {
        case CREATED:
          setActiveComponent("MATCH_SUMMARY");
          return;
        case STARTED:
          setActiveComponent("MATCH_SCORING");
          return;
      }
    }
    status();
  }

  function onRefreshMatchList() {
    async function match_status() {
      const m_address = await matchForPlayer(walletAddress.current);
      if(m_address != 0) {
        const {0:match_name, 1:status, 2:player_list} = await matchStatus(m_address);
        matchState.current = status;
        var dict = {"name":match_name, "address":m_address};
        const m_list = [dict];
        setMatchList(m_list);
      }
    }
    match_status();
  }

  const onSubmitPlayerInfo = async (handicap, bet) => {
    const txAdd = await setInfoForPlayer(walletAddress.current, currentMatchAddress.current, handicap, bet);

    setProgress(true);
  }
  
  const onPlayerScorePosted = async (front, back) => {
    const tx_post = await postScoreForPlayer(walletAddress.current, currentMatchAddress.current, front, back);

    setProgress(true);
  }

  function WalletConnect(props) {
    return (
      <div className="appWrapper">
          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">To get started, connect your wallet.</div>

            <span className="infoText"><br/>Gamblinks uses Metamask.<br/><br/></span>
            <span className="infoText">Make sure you are using the browser inside Metmask wallet.<br/></span>
          </Box>
          
          <Box sx={{ mt:6, ml:1}}>
            <Button
              variant="outlined"
              onClick={connectWalletPressed}
              style={{background: 'rgb(96, 201, 5, 0.1)',
                      borderColor: 'rgb(96, 201, 5, 0.25)',
                      color: 'rgb(182, 228, 149)'
                      }}
            >
              CONNET WALLET
            </Button>
          </Box>
        </div>
    );
  }

  class StartPage extends React.Component {

    constructor(props) {
      super(props);

      this.handleAddMatch = this.handleAddMatch.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);
    }
  
    handleAddMatch(event) {
      event.preventDefault();
      setActiveComponent("CREATE_MATCH");
    }

    handleRefresh(event) {
      event.preventDefault();
      onRefreshMatchList();
    }

    render() {
      const MatchList = (props) => (
        <div style={{ width: "100%" }}>
            <Box>
              <Grid container spacing={0}>
                {matchList.map((d) => <Grid item sx={{ width: "100%", height: "5em"}} key={d.address}><MatchInfo key={d.address} info={d} /></Grid> )}
              </Grid>
            </Box>
          </div>
      );
      const MatchMessage = (props) => (
        <span className="infoText">You don't have any matches yet</span>
      )
      let hasMatches = false;
      if(matchList.length > 0) {
        hasMatches = true;
      }
      return (
        <div className="appWrapperSummary">
          <Box className="addContainer" sx={{top:"6em"}}>
            <Fab style={{minHeight: 56}} sx={{ backgroundColor: "rgb(182, 228, 149)"}} aria-label="add" onClick={this.handleAddMatch}>
              <AddIcon />
            </Fab>
          </Box>
          <Box sx={{ textAlign:"left", marginBottom:"1.5em"}}>
            <div className="mainTitle">Matches</div>
            <span className="subTitle">CONNECTED </span>
            <span className="coordinatorAddress"> {walletAddress.current.slice(0, 6)}...{walletAddress.current.slice(
                walletAddress.current.length - 4,
                walletAddress.current.length
              )}<br/><br/></span>
          </Box>
          {hasMatches ? <MatchList /> : <MatchMessage />}
          <div style={{ height: "8em" }}>
            <Box sx={{ mt:6, ml:1 }}>
              <Button
                variant="outlined"
                onClick={this.handleRefresh}
                style={{background: 'rgb(96, 201, 5, 0.1)',
                        borderColor: 'rgb(96, 201, 5, 0.25)',
                        color: 'rgb(182, 228, 149)'
                        }}
              >
                REFRESH LIST
              </Button>
            </Box>
          </div>
        </div>
    );
    }
  }

  class CreateMatch extends React.Component {

    constructor(props) {
      super(props);

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      
      this.setState({
        [name]: value
      });
    }
  
    handleSubmit(event) {
      event.preventDefault();
      onCreateMatch(this.state.coordinator, this.state.match);
    }

    handleCancel(event) {
      setActiveComponent("START_PAGE");
    }

    render () {
      return (
        <div className="appWrapper">
          <Box className="addContainer" sx={{top:"6em"}}>
            <Fab style={{minHeight: 56}} sx={{backgroundColor: "rgb(255, 255, 255, 0.65)"}} aria-label="arrowBack" onClick={this.handleCancel}>
              <ArrowBack />
            </Fab>
          </Box>
          <Box sx={{ textAlign:"left", marginBottom:"1em"}}>
            <div className="mainTitle">Create a match</div>

            <span className="subTitle">CONNECTED </span>
            <span className="coordinatorAddress"> {walletAddress.current.slice(0, 6)}...{walletAddress.current.slice(
                walletAddress.current.length - 4,
                walletAddress.current.length
              )}</span>
          </Box>
          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <span className="infoText">Create and coordinate a new match. Add players so they can place their bets.</span>
            
          </Box>
          <Box component="form" sx={{ '& > :not(style)': { mb: 2.5, width: '100%' },}}
          noValidate
          autoComplete="off">

            <CustomTextField 
            id="coordinator"
            name="coordinator" 
            label="Your Name" 
            variant="outlined"
            id="filled-hidden-label-normal"
            defaultValue=""
            helperText="Hey, organizer! What's your name?"
            onChange={this.handleInputChange} />

            <CustomTextField 
            id="match" 
            name="match"
            label="Match Name" 
            variant="outlined"
            id="filled-hidden-label-normal"
            defaultValue=""
            helperText="Give your match a name."
            onChange={this.handleInputChange}
            />
          </Box>

          <Box sx={{ mt:6, ml:1}}>
            <Button
              variant="outlined"
              onClick={this.handleSubmit}
              style={{background: 'rgb(96, 201, 5, 0.1)',
                      borderColor: 'rgb(96, 201, 5, 0.25)',
                      color: 'rgb(182, 228, 149)'
                      }}
            >
              Create match
            </Button>
          </Box>
        </div>
      );
    }
  }

  class MatchSummary extends React.Component {

    constructor(props) {
      super(props);

      price();

      this.handleAddPlayer = this.handleAddPlayer.bind(this);
      this.handleStart = this.handleStart.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
    }
  
    handleAddPlayer(event) {
      event.preventDefault();
      setActiveComponent("ADD_PLAYER");
    }

    handleStart(event) {
      event.preventDefault();
      onStartMatch();
    }

    handleDelete(event) {
      event.preventDefault();
      onMatchDelete();
    }

    handlePostScore(event) {
      event.preventDefault();
      setActiveComponent("POST_SCORE");
    }

    render() {
      const AddButton = (props) => (
        <Box className="addContainer">
          <Fab style={{minHeight: 56}} sx={{ backgroundColor: "rgb(182, 228, 149)"}} aria-label="add" onClick={this.handleAddPlayer}>
            <AddIcon />
          </Fab>
        </Box>
      )
      const StartMatchButton = (props) => (
        <Box sx={{ mt:6, ml:1}}>
          <Button
            variant="outlined"
            onClick={this.handleStart}
            style={{background: 'rgb(96, 201, 5, 0.1)',
                    borderColor: 'rgb(96, 201, 5, 0.25)',
                    color: 'rgb(182, 228, 149)'
                    }}
          >
            START MATCH
          </Button>
        </Box>
      )
      const DeleteMatchButton = (props) => (
        <Box sx={{ mt:6, ml:1}}>
          <Button
            variant="outlined"
            onClick={this.handleDelete}
            style={{background: 'rgb(111, 4, 4, 0.2)',
                    borderColor: 'rgb(228, 149, 149, 0.2)',
                    color: 'rgb(228, 149, 149)'
                    }}
          >
            DELETE MATCH
          </Button>
        </Box>
      )
    
      let addButton;
      let startButton;
      let deleteMatchButton;
      if(isCoordinator.current && (matchState.current == CREATED)) {
        addButton = <AddButton />;
        startButton = <StartMatchButton />;
        deleteMatchButton = <DeleteMatchButton />;
      }
      
      return (
        <div className="appWrapperSummary">
          {addButton}
          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">MATCH SUMMARY</div>
          </Box>

          <div style={{ width: "100%", paddingTop: "2px" }}>
            <Box>
              <Grid container spacing={0}>
                {playerList.map((d) => <Grid item sx={{ width: "100%", height: "8em"}} key={d.name}><PlayerInfo key={d.name} info={d} /></Grid> )}
              </Grid>
            </Box>
          </div>
          <div>
            {startButton}
            {deleteMatchButton}
          </div>
        </div>
    );
    }
  }

  class AddPlayer extends React.Component {

    constructor(props) {
      super(props);

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });
    }
  
    handleSubmit(event) {
      event.preventDefault();
      onAddPlayerAddress(this.state.player, this.state.address);
    }

    handleCancel(event) {
      setActiveComponent("MATCH_SUMMARY");
    }

    render() {
      return (
        <div className="appWrapper">
          <Box className="addContainer" sx={{top:"6em"}}>
            <Fab style={{minHeight: 56}} sx={{backgroundColor: "rgb(255, 255, 255, 0.65)"}} aria-label="arrowBack" onClick={this.handleCancel}>
              <ArrowBack />
            </Fab>
          </Box>

          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">PLAYERS</div>
          </Box>

          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <span className="infoText">Add a player to your match. Once players are added, they can submit their bets and handicaps.</span>
            
          </Box>
          <Box component="form" sx={{ '& > :not(style)': { mb: 2.5, width: '100%' },}}
          noValidate
          autoComplete="off">

            <CustomTextField 
            id="player"
            name="player" 
            label="Player Name" 
            variant="outlined"
            id="filled-hidden-label-normal"
            defaultValue=""
            helperText="Enter a name for this player"
            onChange={this.handleInputChange} />

            <CustomTextField 
            id="address" 
            name="address"
            label="ETH Address" 
            variant="outlined"
            id="filled-hidden-label-normal"
            defaultValue=""
            helperText="Enter player's ETH wallet address"
            onChange={this.handleInputChange}
            />
          </Box>

          <Box sx={{ mt:6, ml:1}}>
            <Button
              variant="outlined"
              onClick={this.handleSubmit}
              style={{background: 'rgb(96, 201, 5, 0.1)',
                      borderColor: 'rgb(96, 201, 5, 0.25)',
                      color: 'rgb(182, 228, 149)'
                      }}
            >
              ADD PLAYER
            </Button>
          </Box>
        </div>
    );
    }
  }

  class PlayerEdit extends React.Component {

    constructor(props) {
      super(props);
      
      this.state = {betInETH: 0.0, dollarBet: 0};

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }

    handleBlur(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      if(name === "bet"){
        const inETH = value/currentPrice;
        this.setState({
          betInETH: inETH,
          dollarBet: value
        });
        console.log(inETH);
      }
    }
    
    handleSubmit(event) {
      event.preventDefault();
      const eth = Math.round(this.state.betInETH * 10**18);
      onSubmitPlayerInfo(this.state.handicap, eth.toString(16));
    }
  
    handleCancel(event) {
      setActiveComponent("MATCH_SUMMARY");
    }

    render() {
      return (
        <div className="appWrapper">
           <Box className="addContainer" sx={{top:"6em"}}>
            <Fab style={{minHeight: 56}} sx={{backgroundColor: "rgb(255, 255, 255, 0.65)"}} aria-label="arrowBack" onClick={this.handleCancel}>
              <ArrowBack />
            </Fab>
          </Box>

          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">HANDICAP + BET</div>
          </Box>
                    
          <Box sx={{ textAlign:"left", marginBottom:"1em"}}>
            <span className="nameHeader">{playerName.current}<br/></span>
            <span className="infoText">Enter your course handicap and the amount you want to bet. Remember, all bets are made in ETH.</span>
          </Box>

          <Box component="form" sx={{ '& > :not(style)': { mb: 2.5, width: '100%' },}}
          noValidate
          autoComplete="off">

              <CustomTextField 
                id="handicap"
                name="handicap" 
                label="Course Handicap" 
                id="outlined-adornment-handicap"
                defaultValue=""
                helperText="Enter your course handicap"
                onChange={this.handleInputChange}
              />
              <CustomTextField
                id="bet" 
                name="bet"
                label="Bet $USD"
                id="outlined-adornment-amount"
                defaultValue=""
                helperText="How much you want to bet?"
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
              />
          </Box>

            <div className="betMessage">BET IN ETH</div>
            <div className="betEth">{this.state.betInETH}</div>
            <div className="betCurrent">USD/ETH: ${currentPrice}</div>

          <Box sx={{ mt:6, ml:1}}>
            <Button
              variant="outlined"
              onClick={this.handleSubmit}
              style={{background: 'rgb(96, 201, 5, 0.1)',
                      borderColor: 'rgb(96, 201, 5, 0.25)',
                      color: 'rgb(182, 228, 149)'
                      }}
            >
              SAVE INFO
            </Button>
          </Box>
        </div>
    );
    }
  }

  class MatchScoring extends React.Component {

    constructor(props) {
      super(props);

      this.handlePostScore = this.handlePostScore.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);
      this.handleResults = this.handleResults.bind(this);
    }
  
    handlePostScore(event) {
      event.preventDefault();
      setActiveComponent("POST_SCORE");
    }

    handleRefresh(event) {
      event.preventDefault();
      onRefreshData();
    }

    handleResults(event) {
      event.preventDefault();
      setActiveComponent("MATCH_RESULTS");
    }

    render() {
      const PostScoreButton = (props) => (
        <Box sx={{ mt:6, ml:1}}>
          <Button
            variant="outlined"
            onClick={this.handlePostScore}
            style={{background: 'rgb(96, 201, 5, 0.1)',
                    borderColor: 'rgb(96, 201, 5, 0.25)',
                    color: 'rgb(182, 228, 149)'
                    }}
          >
            POST YOUR SCORE
          </Button>
        </Box>
      )
      const RefreshButton = (props) => (
        <Box sx={{ mt:6, ml:1}}>
          <Button
            variant="outlined"
            onClick={this.handleRefresh}
            style={{background: 'rgb(96, 201, 5, 0.1)',
                    borderColor: 'rgb(96, 201, 5, 0.25)',
                    color: 'rgb(182, 228, 149)'
                    }}
          >
            REFRESH
          </Button>
        </Box>
      )
      const ResultsButton = (props) => (
        <Box sx={{ mt:6, ml:1}}>
          <Button
            variant="outlined"
            onClick={this.handleResults}
            style={{background: 'rgb(96, 201, 5, 0.1)',
                    borderColor: 'rgb(96, 201, 5, 0.25)',
                    color: 'rgb(182, 228, 149)'
                    }}
          >
            MATCH RESULTS
          </Button>
        </Box>
      )
      let actionButton;
      if(scorePosted.current) {
        actionButton = <RefreshButton />;
      } else {
        actionButton = <PostScoreButton />;
      }
      if(matchState.current == ENDED) {
        actionButton = <ResultsButton />;
      }

      return (
        <div className="appWrapperSummary">
          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">SCORING</div>
          </Box>

          <div style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px" }}>
            <Box
              sx={{
                '& > :not(style)': {
                  m: 0,
                  borderRadius: '12px',
                  background: 'rgb(255,255,255,.03)',
                },
              }}
            > 
              <Paper elevation={12} sx={{ paddingTop:"1.5em", paddingBottom:"1.5em" }} >
                <Grid container spacing={2} >
                    {playerList.map((d) => <Grid item sx={{ width: "100%" }} key={d.name}><PlayerScoring key={d.name} info={d} /></Grid> )}
                  </Grid>
              </Paper>
            </Box>
          </div>
          <div>
            {actionButton}
          </div>
        </div>
    );
    }
  }

  class MatchResults extends React.Component {

    constructor(props) {
      super(props);

      this.handleShowScores = this.handleShowScores.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
    }
  
    handleShowScores(event) {
      event.preventDefault();
      setActiveComponent("MATCH_SCORING");
    }

    handleDelete(event) {
      event.preventDefault();
      onMatchDelete();
    }
    render() {
      if(currentPrice == 0) {
        price();
      }

      const DeleteMatchButton = (props) => (
        <Box sx={{ mt:6, ml:0}}>
          <Button
            variant="outlined"
            onClick={this.handleDelete}
            style={{background: 'rgb(111, 4, 4, 0.2)',
                    borderColor: 'rgb(228, 149, 149, 0.2)',
                    color: 'rgb(228, 149, 149)'
                    }}
          >
            DELETE MATCH
          </Button>
        </Box>
      )
      let deleteMatchButton;
      if(isCoordinator.current) {
        deleteMatchButton = <DeleteMatchButton />;
      }
      let winners = [];
      for(let i=0; i < playerList.length; i++){
        const player = playerList[i];
        if(player.bets_won.length > 0) {
            winners.push(player);

        }
      }
    
      return (
        <div className="appWrapperSummary">
          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">RESULTS + PAYOUT</div>
          </Box>

          <div style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px" }}>
            <Box
              sx={{
                '& > :not(style)': {
                  m: 0,
                  borderRadius: '12px',
                  background: 'rgb(255,255,255,.03)',
                },
              }}
            > 
              <Paper elevation={12} sx={{ paddingTop:"1.75em", paddingBottom:"1.75em" }} >
                <Grid container spacing={0}>
                  {winners.map((d) => <Grid item sx={{ width: "100%" }} key={d.name}><PayoutItem key={d.name} info={d}/></Grid> )}
                </Grid>
              </Paper>
            </Box>
          </div>
          <div>
            <Box sx={{ mt:6, ml:0}}>
              <Button
                variant="outlined"
                onClick={this.handleShowScores}
                style={{background: 'rgb(96, 201, 5, 0.1)',
                        borderColor: 'rgb(96, 201, 5, 0.25)',
                        color: 'rgb(182, 228, 149)'
                        }}
              >
                FINAL SCORES
              </Button>
              {deleteMatchButton}
            </Box>
          </div>
        </div>
    );
    }
  }

  class PlayerInfo extends React.Component {

    constructor(props) {
      super(props);

    }

    handleTap() {
      if(this.props.info.addr.toLowerCase() === walletAddress.current.toLowerCase()) {
          if((this.props.info.bet === "0")) {
            playerName.current = this.props.info.name;
            onPlayerInfoTap();
        }
      }
    }

    render() {
      const betInUSD = (this.props.info.bet / 10**18) * currentPrice;
      const TapMessage = (props) => (
        <div className="tapContainer">
          <div className="tapToEdit">{props.message}</div>
        </div>
      )
      let tapMessage = <TapMessage message=""/>;
      if (this.props.info.addr.toLowerCase() === walletAddress.current.toLowerCase()) {
        if(this.props.info.bet === "0") {
          tapMessage = <TapMessage message="tap to edit"/>
        }
      }
      
      return (
        <Box
            sx={{
              '& > :not(style)': {
                m: 0,
                borderRadius: '12px',
                background: 'rgb(255,255,255,.12)',
              },
            }}
          >
            <Paper elevation={12} onClick={() => this.handleTap()}>
              <div className="infoWrapper" key={this.props.info.name}>
                <div className="nameContainer">
                  <div className="nameText">{this.props.info.name}</div>
                </div>
                <div className="addressContainer">
                  <div className="addressText">{this.props.info.addr.slice(0, 6)}...{this.props.info.addr.slice(
                        this.props.info.addr.length - 4,
                        this.props.info.addr.length
                      )}</div>
                </div>
                <div className="handicapContainer">
                  <span className="handiLabel">Hcp </span><span className="handiNum">{this.props.info.handicap}</span>
                </div>
                <div className="betContainer">
                  <div className="betText">${Math.round(betInUSD)}</div>
                </div>
                {tapMessage}
              </div>
            </Paper>
          </Box>
       ) 
    }
  }

  class PlayerScoring extends React.Component {

    constructor(props) {
      super(props);

    }

    render() {
      let front_gr = parseFloat(this.props.info.score.front);
      let back_gr = parseFloat(this.props.info.score.back);
      let total_gr = parseFloat(front_gr + back_gr);
      let handi = parseFloat(this.props.info.handicap);
      let front_net = parseFloat(front_gr - (handi/2));
      let back_net = parseFloat(back_gr - (handi/2));
      let total_net = parseFloat(total_gr - handi);
      if(front_gr == 0 || back_gr == 0) {
        front_gr = "--";
        back_gr = "--";
        total_gr = "--"
        front_net = "";
        back_net = "";
        total_net = "";
      }
      return (
        <div className="scoringWrapper">
            <div className="scoringName playerName">{this.props.info.name}</div>
            <div className="scoringHeaderFront headerCaps">FRONT</div>
            <div className="scoringHeaderBack headerCaps">BACK</div>
            <div className="scoringHeaderTotal headerCaps">TOTAL</div>

            <div className="scoringValueFront">
              <span className="scoringGross">{front_gr}</span>
              <span className="scoringSeperator">/</span>
              <span className="scoringNet">{front_net}</span>
            </div>
            <div className="scoringValueBack">
              <span className="scoringGross">{back_gr}</span>
              <span className="scoringSeperator">/</span>
              <span className="scoringNet">{back_net}</span>
            </div>
            <div className="scoringValueTotal">
              <span className="scoringGross">{total_gr}</span>
              <span className="scoringSeperator">/</span>
              <span className="scoringNet">{total_net}</span>
            </div>
        </div>
       ) 
    }
  }

  class MatchInfo extends React.Component {

    constructor(props) {
      super(props);

    }

    handleTap() {
      onMatchListTap(this.props.info.name, this.props.info.address);
    }

    render() {
      return (
        <Box
            sx={{
              '& > :not(style)': {
                m: 0,
                borderRadius: '12px',
                background: 'rgb(255,255,255,.05)',
              },
            }}
          >
            <Paper elevation={12} onClick={() => this.handleTap()}>
              <div className="infoWrapper" key={this.props.info.name}>
                <div className="nameContainer">
                  <div className="nameText">{this.props.info.name}</div>
                </div>
                <div className="addressContainer">
                  <div className="addressText">in progress...</div>
                </div>
              </div>
            </Paper>
          </Box>
       ) 
    }
  }

  class PayoutItem extends React.Component {

    constructor(props) {
      super(props);
      
    }

    render() {

      let total_usd = 0;
      let total_eth = 0;
      let bets = [];
      this.props.info.bets_won.forEach((element, index, array) => {
        const usd = Math.round((element[1] / 10**18) * currentPrice);
        const eth = parseFloat(element[1] / 10**18).toFixed(3);
        const score = element[2];
        var net = score - (this.props.info.handicap/2);
        if(parseInt(this.props.info.handicap) == 0) {
          net = "";
        }
        total_usd += usd;
        total_eth += parseFloat(element[1]);
        let info = {"score":score, "net":net, "eth":eth, "usd":usd};
        switch(parseInt(element[0])) {
          case FRONT:
          info["name"] = "FRONT";
          break;
        case BACK:
          info["name"] = "BACK";
          break;
        case TOTAL:
          info["name"] = "TOTAL";
          break;

        }
        
        bets.push(info);
      });

      total_eth = (total_eth / 10**18).toFixed(3);

      const BetItem = (props) => (
        <div className="resultsValues">
          <div className="resultsTitle headerCaps">{props.info.name}</div>
          <Box sx={{ display:"flex", flexDirection:"row", textAlign:"left"}}>
            <Box sx={{ paddingRight:"2px" }} className="resultsGross">{props.info.score}</Box>
            <Box sx={{ paddingRight:"2px" }} className="resultsNet">{props.info.net}</Box>
            <Box sx={{ paddingRight:"2px" }} className="resultsSeperator">|</Box>
            <Box sx={{ paddingRight:"2px" }} className="resultsBetDollar">${props.info.usd}</Box>
          </Box>
        </div>
      )
    
      return (
        <div style={{ paddingLeft:"1.2em", paddingBottom:"1.4em" }}>
          <div className="resultsName">{this.props.info.name}</div>
          <div className="resultsValues" style={{marginBottom: "0.3em"}}>
            <span className="resultsDollar">${total_usd}</span>
            <span className="resultsEth">{total_eth}ETH</span>
          </div>
          <div >
            <Grid container spacing={2} direction="row">
              {bets.map((d) => <Grid item key={d.name}><BetItem key={d.name} info={d} /></Grid> )}
            </Grid>
          </div>
        </div>
       ) 
    }
  }

  class PostScore extends React.Component {

    constructor(props) {
      super(props);
      
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCancel = this.handleCancel.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
    }

    handleSubmit(event) {
      event.preventDefault();
      onPlayerScorePosted(this.state.front, this.state.back);
    }

    handleCancel(event) {
      event.preventDefault();
      setActiveComponent("MATCH_SCORING");
    }

    render() {
      return (
        <div className="appWrapper">
           <Box className="addContainer" sx={{top:"4em"}}>
            <Fab style={{minHeight: 56}} sx={{backgroundColor: "rgb(255, 255, 255, 0.65)"}} aria-label="arrowBack" onClick={this.handleCancel}>
              <ArrowBack />
            </Fab>
          </Box>

          <Box sx={{ textAlign:"left", marginBottom:"3em"}}>
            <div className="mainTitle">{matchName.current}</div>
            <div className="subTitle">POST</div>
          </Box>
                    
          <Box sx={{ textAlign:"left", marginBottom:"1em"}}>
            <span className="nameHeader">{playerName.current}<br/></span>
            <span className="infoText">Post your gross scores for front and back nines. Net scores are calculated automatically.</span>
          </Box>

          <Box component="form" sx={{ '& > :not(style)': { mt: 2.5, mx: '5%', width: '40%' },}}
          noValidate
          autoComplete="off">
              <CustomTextField 
                id="front"
                name="front" 
                label="front" 
                id="outlined-adornment-handicap"
                defaultValue=""
                helperText="Gross Score"
                onChange={this.handleInputChange}
              />
              
              <CustomTextField
                id="back" 
                name="back"
                label="back"
                id="outlined-adornment-amount"
                defaultValue=""
                helperText="Gross Score"
                onChange={this.handleInputChange}
                onBlur={this.handleBlur}
              />
            </Box>
          <Box sx={{ mt:6, ml:1}}>
            <Button
              variant="outlined"
              onClick={this.handleSubmit}
              style={{background: 'rgb(96, 201, 5, 0.1)',
                      borderColor: 'rgb(96, 201, 5, 0.25)',
                      color: 'rgb(182, 228, 149)'
                      }}
            >
              POST SCORE
            </Button>
          </Box>
        </div>
    );
    }
  }
  
  class Loading extends React.Component {
    render(){
      return(
        <div>
          <div className="overlay">
            <div id='outer'>
            <div id='middle'>
              <div id='inner'>   
              </div>
              </div>
              </div>
          </div>
          <div className="overlayBackground" />
        </div>
      );
    }
  }

  function SwitchComponents({ active, children }) {
    // Switch all children and return the "active" one
    return children.filter(child => child.props.name === active)
  }

  //the UI of our component
  return (
    <div className="App">
    <div className="headerImg">
      <img id="logo" src={mylogo} />
    </div>
    
      {progress && ( <Loading /> )}
      <SwitchComponents active={activeComponent}>
        <WalletConnect name="CONNECT_WALLET" />
        <StartPage name="START_PAGE" />
        <CreateMatch name="CREATE_MATCH" />
        <MatchSummary name="MATCH_SUMMARY" />
        <AddPlayer name="ADD_PLAYER" />
        <PlayerEdit name="EDIT_PLAYER" />
        <MatchScoring name="MATCH_SCORING" />
        <PostScore name="POST_SCORE" />
        <MatchResults name="MATCH_RESULTS" />
      </SwitchComponents>
    
    </div>
  )

};

export default Gambler;
