import Web3 from 'web3';
require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 
//const web3 = new Web3(new Web3.providers.WebsocketProvider('ws://localhost:8545'))


const matchFactoryABI = require("../MatchFactory-abi.json");
const contractAddress = "0xC81e53AB9C59511378B6c0ea5a26b56a4C2A98f2"; // Local 0x0A9da5C24201460Fd530bef4b5e58Cc9d66949A6 
const golfMatchABI = require("../GolfMatch-abi.json");

export const matchFactory = new web3.eth.Contract(
    matchFactoryABI,
    contractAddress
  );

export const currentMatch = (matchAddress) => {
    return new web3.eth.Contract(
        golfMatchABI,
        matchAddress
    );
}

export const createMatch = async (walletAddress, coordinatorName, matchName) => {
    console.log("INTERACT IS GONNA CREATE A MATCH");
    console.log(coordinatorName);
    console.log(matchName);
    const transactionParameters = {
        to: contractAddress,
        from: walletAddress,
        data: matchFactory.methods.createMatch(coordinatorName, matchName).encodeABI(),
    };
    //sign the transaction
    try {
    const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
    });

    return txHash;
    /*return {
        status: (
        <span>
            ✅{" "}
            <a target="_blank" href={`https://rinkeby.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
            </a>
            <br />
            ℹ️ Once the transaction is verified by the network, the message will
            be updated automatically.
        </span>
        ),
    };*/
    } catch (error) {
    return {
        status: "😥 " + error.message,
    };
    }
};

export const startMatch = async (walletAddress, matchAddress) => {
  const transactionParameters = {
    to: matchAddress,
    from: walletAddress,
    data: currentMatch(matchAddress).methods.startMatch().encodeABI(),
  };
  console.log(transactionParameters);
  //sign the transaction
  try {
    const tx_hash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return tx_hash;
  } catch (error) {
    console.log("Can't start damn match");
  }
};

export const addPlayer = async (walletAddress, matchAddress, playerName, playerAddress) => {
    console.log("INTERACT IS GONNA ADD A PLAYER");
    console.log(walletAddress);
    console.log(matchAddress);
    console.log(playerName);
    console.log(playerAddress);
    
    const transactionParameters = {
        to: matchAddress,
        from: walletAddress,
        data: currentMatch(matchAddress).methods.add_player(playerName, playerAddress).encodeABI(),
      };
      console.log(transactionParameters);
      //sign the transaction
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
        return {
          status: (
            <span>
              ✅{" "}
              <a target="_blank" href={`https://rinkeby.etherscan.io/tx/${txHash}`}>
                View the status of your transaction on Etherscan!
              </a>
              <br />
              ℹ️ Once the transaction is verified by the network, the message will
              be updated automatically.
            </span>
          ),
        };
      } catch (error) {
        return {
          status: "😥 " + error.message,
        };
      }
};

export const setInfoForPlayer = async (walletAddress, matchAddress, handicap, bet) => {
  console.log("PLACING BET: %s", walletAddress);

  const transactionParameters = {
    to: matchAddress,
    from: walletAddress,
    value: bet,
    data: currentMatch(matchAddress).methods.updatePlayerInfo(handicap).encodeABI(),
  };
  console.log(transactionParameters);
  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return txHash;
    /*{
      status: (
        <span>
          ✅{" "}
          <a target="_blank" href={`https://rinkeby.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, the message will
          be updated automatically.
        </span>
      ),
    };*/
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
};

export const postScoreForPlayer = async (walletAddress, matchAddress, front, back) => {
  console.log("Posting score for: %s", walletAddress);

  const transactionParameters = {
    to: matchAddress,
    from: walletAddress,
    data: currentMatch(matchAddress).methods.postScore(front, back).encodeABI(),
  };
  
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return txHash;
  } catch (error) {
    console.log("Couldn't post score");
  }
};

export const matchForPlayer = async (walletAddress) => {
  const match_address = await matchFactory.methods.query_player_address(walletAddress).call();
  return match_address;
}

export const matchStatus = async (matchAddress) => { 
    console.log("GETTING STATUS: %s", matchAddress);
    const {0: match_name, 1:status, 2: player_list} = await currentMatch(matchAddress).methods.get_match_status().call();
    return {0: match_name, 1:status, 2: player_list};
};

export const getPrice = async() => {
  const price = await matchFactory.methods.get_price().call();
  return price;
}

export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Write a message in the text-field above.",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
